import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "husqvarna" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "husqvarna-elcyklar-2024"
    }}>{`Husqvarna Elcyklar 2024`}</h1>
    <p>{`Välkommen till din ultimata guide för Husqvarna elcyklar! Husqvarna är kända för högkvalitativa elcyklar som erbjuder prestanda, komfort och säkerhet för både stad och terräng. Med avancerad teknologi och innovativ design, har Husqvarna en elcykel för varje stil och behov. Utforska de olika serierna nedan och hitta den perfekta elcykeln för dig!`}</p>
    <h2 {...{
      "id": "husqvarna-tourer"
    }}>{`Husqvarna Tourer`}</h2>
    <p><strong parentName="p">{`Husqvarna Tourer`}</strong>{` är den perfekta hybridcykeln för både urban pendling och landsbygdens natursköna vägar. Med en kraftfull Bosch Performance Line-motor och långvarigt Bosch PowerTube-batteri är Tourer-serien byggd för att klara långa avstånd med överlägsen komfort. Modeller i denna serie inkluderar funktioner som dämpning, Tektro skivbromsar, och ett intuitivt display-gränssnitt för optimal användarvänlighet. För den som söker en pålitlig cykel för varje äventyr, är Husqvarna Tourer ett självklart val.`}</p>
    <h2 {...{
      "id": "husqvarna-hard-cross"
    }}>{`Husqvarna Hard Cross`}</h2>
    <p><strong parentName="p">{`Husqvarna Hard Cross`}</strong>{`-serien är specialiserad för enduro och off-road äventyr. Med kraftfulla Shimano EP6 motorer och robusta 630 Wh batterier, är dessa heldämpade elcyklar designade för att tackla de tuffaste terrängerna. Högkvalitativa SRAM GX Eagle växlar och premiumdäck som Schwalbe Magic Mary säkerställer maximal kontroll och prestanda. Oavsett om du ska navigera tekniska stigar eller kraftiga uppförsbackar, erbjuder Hard Cross den kraft och stabilitet du behöver.`}</p>
    <h2 {...{
      "id": "husqvarna-crosser"
    }}>{`Husqvarna Crosser`}</h2>
    <p>{`För de som söker en mångsidig hybridcykel, erbjuder `}<strong parentName="p">{`Husqvarna Crosser`}</strong>{` flexibel användning på både asfalt och grus. Med Bosch Performance Line-motorer och effektiva batterilösningar, anpassar sig Crosser-serien enkelt till stadsmiljöer eller mer utmanande terränger. Denna serie är utrustad med avancerad dämpning och precis bromskraft från Tektro-skivbromsar, perfekt för blandade cykelleder.`}</p>
    <h2 {...{
      "id": "husqvarna-grand-towner"
    }}>{`Husqvarna Grand Towner`}</h2>
    <p><strong parentName="p">{`Husqvarna Grand Towner`}</strong>{` är det perfekta valet för stadens puls och dynamiska körförhållanden. Utrustad med Bosch eller Yamaha motorer, samt robusta batterier, ger Grand Towner en smidig och kraftfull körupplevelse. Denna serie fokuserar på stadskomfort med funktioner som dämpning, ergonomiska komponenter och Nexus växelsystem för smidiga övergångar.`}</p>
    <h2 {...{
      "id": "husqvarna-grand-city-och-eco-city"
    }}>{`Husqvarna Grand City och Eco City`}</h2>
    <p><strong parentName="p">{`Husqvarna Grand City`}</strong>{` och `}<strong parentName="p">{`Eco City`}</strong>{` är designade för den urbana cyklisten som vill ha en pålitlig och effektiv transportlösning. Dessa cyklar är lätta att hantera och utrustade med kraftfulla Yamaha eller Shimano motorer. Med hållbara batterier och högteknologiska funktioner som integrerade display-system, erbjuder dessa serier både komfort och stil för den krävande stadsresenären.`}</p>
    <h2 {...{
      "id": "husqvarna-mountain-cross"
    }}>{`Husqvarna Mountain Cross`}</h2>
    <p><strong parentName="p">{`Husqvarna Mountain Cross`}</strong>{` erbjuder en plattform för seriös mountainbike-entusiaster som söker äventyr i krävande terränger. Med en mulletkonfiguration (större framhjul än bakhjul) och avancerade Shimano EP6-motorer, är dessa cyklar byggda för att hantera extrem terräng. Heldämpning och robusta konstruktioner garanterar att Mountain Cross tar dig längre och högre på dina cykeläventyr.`}</p>
    <h3 {...{
      "id": "köpguide"
    }}>{`Köpguide`}</h3>
    <p>{`När du väljer rätt Husqvarna elcykel, överväg vad du huvudsakligen ska använda cykeln till:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`För stadspendling:`}</strong>{` Välj Grand City eller Eco City för smidighet och komfort.`}</li>
      <li parentName="ul"><strong parentName="li">{`För landsväg och lätt terräng:`}</strong>{` Överväg Tourer eller Crosser för mångsidighet.`}</li>
      <li parentName="ul"><strong parentName="li">{`För tuffa terränger och enduro:`}</strong>{` Hard Cross eller Mountain Cross ger optimal prestanda och kontroll.`}</li>
    </ul>
    <p>{`Husqvarna elcyklar är synonymt med kvalitet och innovation. Hitta din nästa elcykel och ta steget mot nya äventyr idag!`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      